// Colors

$white: #FFFFFF;
$offwhite: #F5F5F5;
$gray: #E0E0E0;
$darkgray: #9E9E9E;
$flatblack: #424242;
$black: #212121;
$blue: #2196F3;
$darkblue: #1873BB;
$red: #F44336;
$darkred: #BD342A;
$lightblue: #98D1FF;
$fiveblue: #f4fafe;
$fivered: #fef5f5;
$green: #7ed321;
// still backward?!!?
// $redblue: linear-gradient(-135deg, #2196F3 0%, #D93C30 100%);
// $popular: radial-gradient(50% 100%, #FD0808 25%, #C60202 100%);
// $newrelease: radial-gradient(50% 100%, #5EC300 25%, #14BA00 100%);
// $awardwinner: radial-gradient(50% 100%, #FFBA2E 25%, #FC9D4B 100%);
$redblue: linear-gradient(135deg, #2196F3 0%, #D93C30 100%);
$popular: radial-gradient(50% 100%, #FD0808 25%, #C60202 100%);
$newrelease: radial-gradient(50% 100%, #5EC300 25%, #14BA00 100%);
$awardwinner: radial-gradient(50% 100%, #FFBA2E 25%, #FC9D4B 100%);


// Body
$body-bg: $white;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger:  #bf5329;

// Typography
$font-family-sans-serif: "Open Sans", sans-serif;
$font-size-base: 16px;
$line-height-base: 1.5;
$text-color: $black;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;
