// Typography

h1 {
  color: $black;
  font-size: 48px;
  font-weight: 600;
  line-height: 57px;
  &.pageh1 {
    position: absolute;
    margin-left: -9999em;
  }
  @media (max-width: $screen-md-min) {
    font-size: 36px;
    line-height: 44px;
  }
}

h2 {
  color: $black;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  @media (max-width: $screen-md-min) {
    font-size: 28px;
    line-height: 36px;
  }
}

h3 {
  color: $black;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
}

h4 {
  color: $black;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
}

h5 {
  color: $black;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}

.black-text * {
  color: $black !important;
}

.price-text {
  font-size: 64px;
  margin-top: 50px;
  margin-bottom: 50px;

  &.first {
    margin-bottom: 10px;
  }

  &.last {
    margin-top: 10px;
  }
}

.extra-margin-bottom {
  margin-bottom: 45px;
}

.no-margins {
  margin: 0;
}

.list-group.flat-black {
  .list-group-item.flex-list-container {

    background-color: $flatblack;

    display: flex;

    justify-content: space-between;

    .flex-list-item {
      &:first-child {
        align-self: flex-start;
      }
      &:last-child {
        align-self: flex-end;
      }
    }
  }
}

.caption, .excerpt {
  color: $black;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  i {
    font-size: 22px;
    transform: translateY(3px);
  }
}

.fine-print {
  color: $black;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
}

.uppercase {
  text-transform: uppercase;
}

.big-blue {
  color: $blue;
  font-size: 36px;
  font-weight: 600;
  margin-top: -15px;
}

.big-green {
  color: $green;
  font-size: 36px;
  font-weight: 600;
  margin-top: -15px;
}

// master structure

.equalizer {
  display: flex;
  flex-flow: row wrap;
  & > div {
    display: flex;
    flex-direction: column;
  }
  .panel {
    flex: 1;
  }
  .col-md-4 {
    flex-grow: 1;
    flex-basis: 100%;
    @media (min-width: $screen-md-min) {
      flex-basis: 33.3%;
    }
  }
  &.row::before, &.row::after {
    display: none;
  }
}
@media (min-width: $screen-lg-min) {
  .container-fluid {
    margin: 0 80px;
  }
}

.panel {
  border-radius: 0;
  border: 1px solid $gray;
  .panel-heading {
    background: $flatblack;
    border-radius: 0;
    border: none;
    color: $white;
    padding: 10px 30px;
    h5 {
      color: $white;
      line-height: 30px;
      margin: 0;
    }
  }
  .panel-body {
    padding: 10px 30px;
  }
}

.table-bordered {
  border: 1px solid $gray;
  th {
    background: $lightblue;
    color: $flatblack;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
  }
  td {
    color: $black;
    font-size: 16px;
    text-align: center;
  }
}

.table-striped {
  th {
    background: $lightblue;
    color: $flatblack;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    text-align: left;
  }
  td {
    border: 1px solid $gray;
  }
}

body .pagination {
  &> li {
    font-size: 14px;
  }
  &> li > a {
    border: none;
    border-radius: 2px;
    color: $blue;
    line-height: 30px;
    padding: 0;
    text-align: center;
    width: 30px;
    &:hover {
      background: $gray;
      color: $blue;
    }
  }
  &> .disabled > span {
    border: none;
    color: $darkgray;
    line-height: 30px;
    padding: 0;
    text-align: center;
    width: 30px;
  }
  &> .active > span {
    background: $blue;
    border: none;
    border-radius: 2px;
    line-height: 30px;
    padding: 0;
    text-align: center;
    width: 30px;
    &:hover {
      background: $blue;
    }
  }
}

.empty {
  padding: 50px 0;
}

footer {
  background-color: $flatblack;
  .logo {
    width: 99px;
    margin-right: 20px;
  }
  p {
    color: white;
    font-size: 12px;
    line-height: 80px;
    margin: 0;
  }
  ul {
    margin: 0;
    padding: 0;

    @media (max-width: $screen-md-min) {
      margin-right: -15px;
      margin-left: -15px;
    }
    li {
      display: inline-block;
      font-size: 16px;
      line-height: 80px;
      padding: 0 15px;
      text-transform: uppercase;

      @media (max-width: $screen-md-min) {
        font-size: 14px;
      }
      a {
        color: $white;
        &:hover {
          color: $white;
        }
      }
    }
  }
  .single-film & {
    margin-top: 50px;
  }
  @media (max-width: $screen-md-min) {
    .pull-left {
      float: none !important;
      min-width: 100%;
      img {
        display: block;
        margin: 30px auto;
      }
      p {
        line-height: 15px;
        text-align: center;
      }
    }
    .pull-right {
      clear: both;
      float: none !important;
      ul {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-top: 20px;

        li {
          line-height: 35px;

          text-align: center;
        }
      }
    }
  }
}

.redblue {
  background: $redblue;
}

.liked-like {
  color: $blue;
  a, i {
    color: $blue !important;
  }
}

// nav/header
@media (min-width: $screen-lg-min) {
  nav .container-fluid, footer .container-fluid, .control-bar .container-fluid {
    margin: 0 50px;
  }
}

.navbar {
  border-bottom: none;
  margin-bottom: 0;
  ul.nav {
    li a {
      color: $black;
      font-size: 18px;
      line-height: 54px;
      padding: 10px 25px;
      text-transform: uppercase;
      &:hover {
        color: $darkred;
      }
    }
  }
}

.logo {
  img {
    max-width: 96px;
    margin: 12px 16px;
  }
}


@media (min-width: $screen-lg-min) {
  .logo {
    img {
      max-width: 96px;
      margin: 16px 0;
    }
  }
}

.navbar-toggle {
  margin-top: 18px;
  margin-bottom: 18px;
}

.control-bar {
  background: $redblue;
  height: 50px;
  position: relative;
  .control-status {
    color: $white;
    float:left;
    line-height: 50px;
  }
  p {
    margin: 0;
  }
  .container-fluid > ul {
    display: flex;
    justify-content: space-between;
    @media (min-width: $screen-sm-min) {
      display: block;
    }
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: right;
    li {
      display: inline-block;
      line-height: 50px;
      padding: 0 10px;
      a {
        color: $white;
        line-height: 50px;
        text-decoration: none;
      }
    }
  }
  .caption {
    color: $white;
    line-height: 50px;
    a {
      line-height: 50px;
    }
  }
}

.caption ul.dropdown-menu, .tt-menu {
  background-color: $white;
  border-radius: 0px;
  border: 1px solid $gray;
  border-top: none;
  border-bottom: none;
  box-shadow: 0 20px 10px 0 rgba(0,0,0,.20);
  line-height: 38px;
  min-width: 0;
  padding: 0px 0 5px 0;
  text-align: left;
  li {
    line-height: 38px;
    padding: 0;
    width: 100%;
    a {
      color: $black;
      font-size: 14px;
      line-height: 40px;
      padding: 0 30px;
      &:hover {
        background-color: $blue;
        color: $white;
      }
    }
  }
  &::after{
    background: $redblue;
    bottom: 0px;
    left: 0px;
    content: "";
    height: 5px;
    position: absolute;
    width: 100%;
  }
}

@media (max-width: $screen-md-min) {
  .mobile-right {
    left: auto;
    right: 0;
  }
}
// pagebuilder

.page-builder-components {
  img {
    max-width: 100%;
    &.award {
      display: block;
      margin: auto;
      max-width: 80%;
      width: 275px;
    }
  }
}

.theme-light-bg, .theme-wide {
  .container-fluid {
    background: $white;
    position: relative;
  }
  video {
    background: $white;
  }
  h1, h2, h3, h4, h5, h6, ul, li, p, .paragraph {
    color: $black;
  }
}

.theme-dark-bg {
  background: $black;
  video {
    background: $black;
  }
  h1, h2, h3, h4, h5, h6, ul, li, p, .paragraph {
    color: $white;
  }
}

.theme-gradient {
  background: $redblue;
  video {
    background: $redblue;
  }
  h1, h2, h3, h4, h5, h6, ul, li, p, .paragraph {
    color: $white;
  }
}

.theme-flatblack {
  background: $flatblack;
  video {
    background: $flatblack;
  }
  h1, h2, h3, h4, h5, h6, ul, li, p, .paragraph {
    color: $white;
  }
}

.theme-flatblack-wide {
  background: $flatblack;
  .container-fluid {
    background: $flatblack;
    position: relative;
  }
  video {
    background: $flatblack;
  }
  h1, h2, h3, h4, h5, h6, ul, li, p, .paragraph {
    color: $white;
  }
}

.columns {
  padding: 20px 0;
}

.hero-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  min-height: 60vh;
  justify-content: center;
  width: 100%;
  h1, h2, h3, h4, h5, h6, ul, li, p, .paragraph {
    text-align: center;
  }
}

.hero-video {
  background-size: cover;
  padding: 30px;
  @media (min-width: $screen-lg-min) {
    padding: 90px;
  }
  .single-film & {
    padding: 45px;
  }
  position: relative;
  .video {
    background-color: #000;
    padding: 0;
  }
  video {
    max-width: 100%;
  }
}

.hero-video-autoplay {
  background: transparent;
  display: flex;
  flex-direction: column;
  @media (min-width: $screen-md-min) {
    height: 70vh;
  }
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;


  & > .row.equalizer {
    @media (max-width: $screen-md-min) {
      margin-right: 0;
      margin-left: 0;
    }

    h4 {
      @media (max-width: $screen-md-min) {
        color: black;
      }
    }
  }

  .bgvid {
    display: block;
    font-size: 0;
    opacity: 1;
    overflow: hidden;

    @media (min-width: $screen-md-min) {
      position: absolute;
      top: 0;
    }

    z-index: -1;
    width:100%;
    @media (max-width: $screen-md-min) {
      height: 54vw;
    }
    video {
      width: 100%;
      @media (min-width: $screen-md-min) {
        min-height: 100%;
        min-width: 100%;
      }
    }
  }

  & > h2 {
    @media (max-width: $screen-md-min) {
      position: absolute;
      top: 5%;
    }
  }

  h2, p {
    text-align: center;
  }
  h2 {
    font-size: 36px;
    line-height: 44px;
    @media (min-width: $screen-md-min) {
      font-size: 48px;
      line-height: 57px;
    }
  }
}

.image-break {
  background-position: center;
  background-size: cover;
  @media (min-width: $screen-md-min) {
    background-attachment: fixed;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 65vh;
  margin: -80px 0;
  width: 100%;
  h2 {
    text-align: center;
    font-size: 48px;
    line-height: 57px;
    @media (max-width: $screen-sm-min) {
      font-size: 36px;
      line-height: 44px;
    }
  }

}

.paragraph {
  padding: 30px;
  video {
    max-width: 100%;
  }
  @media (max-width: $screen-md-min) {
    h2 {
      font-size: 24px;
      line-height: 33px;
    }
  }
  strong, em {
    display: inline;
  }
}

.interact-box {
  border: 1px solid $gray;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: $screen-md-min) {
    height: 200px;
  }
  padding: 30px;
  text-align: center;
  transition: 100ms all;
  * {
    transition: 100ms all;
  }
  .circle-icon {
    border-radius: 9999em;
    background: $redblue;
    color: $white;
    height: 75px;
    margin: auto;
    text-align: center;
    width: 75px;
    i {
      font-size: 36px;
      line-height: 75px;
    }
  }
  p {
    color: $black;
    font-weight: 600;
    margin: 10px;
  }
  &:hover {
    background-color: $flatblack;
    p {
      color: $white;
    }
  }
}

.register-form-shortcode, .donate-form, .buy-credit-form {
  border: 1px solid $gray;
  border-top: none;
  padding: 15px;
  position: relative;
  &::before {
    background: $redblue;
    content: "";
    height: 10px;
    left:-1px;
    right: -1px;
    position: absolute;
    top: -10px;

  }
}

.donate-form, .buy-credit-form {
  margin: 15px 0;
}

.donate {
  .panel {
    margin-bottom: 50px;
    .panel-heading {
      background-color: $blue;
    }
  }
}

// ui

input[type=text], input[type=email], input[type=password], textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

 .popular {
   height: 50px;
   width: 150px;
   background: $popular;
 }
.button {
  /* normal: */
  background: $blue;
  border:none;
  border-radius: 2px;
  box-shadow: 0px 2px 0px 0px rgba(0,0,0,0.50);
  color: $white;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  line-height: 50px;
  padding-left: 30px;
  padding-right: 30px;
  text-transform: uppercase;
  transition: all 100ms ease;

  &.squished.center-hero {
    margin: 0 auto;
    width: 60%;
  }

  &:hover {
    background: $darkblue;
    color: $white;
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
  &:focus {
    color: $white;
    text-decoration: none;
  }
  &:active {
    box-shadow: none;
    transform: translateY(2px);
  }
  &.button-secondary {
    background: $red;
    &:hover {
      background: $darkred;
    }
  }
  &.button-small {
    font-size: 14px;
    line-height: 30px;
    padding-left: 18px;
    padding-right: 18px;
  }
  &.button-ghost-tiny {
    background: transparent;
    border: 1px solid $white;
    box-shadow: none;
    color: $offwhite;
    font-size: 12px;
    line-height: 25px;
    padding-left: 15px;
    padding-right: 15px;
    &:hover {
      background: $white;
      color: $black;
    }
    &:active {
      transform: translateY(0);
    }
  }
  &.disabled {
    background-color: $gray;
    color: $flatblack;
    cursor: default;
    pointer-events: none;
  }
}

.alert {
  margin: 15px;
}

.img-tiny {
  float: left;
  height: 20px;
  margin-right: 5px;
  width: 20px;
}
.file-library {
  .row {
    height: 175px;
    overflow: scroll;
  }
}
.file-selector {
  cursor: pointer;
  line-height: 25px;
  height: 25px;
  i, p {
    line-height: 25px;
  }
  &:hover {
    background-color: $lightblue;
  }
  .fine-print {
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
  }
}

.bumpy {
  margin-top: 30px;
}

.control-label {
  color: $flatblack;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  .has-error & {
    color: $flatblack;
  }
}

.form-group {
  margin-bottom: 30px;
  label {
    display: block;
  }
}

.form-control {
  height: 50px;
  padding: 6px 12px;
  color: $black;
  border: 1px solid $gray;
  border-radius: 0px;
  box-shadow: none;
  &::placeholder {
    color: $darkgray;
  }
  &:focus {
    background-color: $fiveblue;
    border-color: $blue;
    box-shadow: none;
  }
  .has-error &
  {
    border-color: $red;
    &:focus {
      background-color: $fivered;
      border-color: $red;
      box-shadow: none;
    }
  }
  td & {
    border: none;
  }
}

.has-error .help-block {
  color: $red;
}

.modal-content {
  border-radius: 0;
  padding: 10px;
  position: relative;
  .modal-logo {
    max-width: 180px;
    display: block;
    margin: 0 auto 0px;
  }
  .modal-header, .modal-footer {
    border: none;
  }
  &::before {
    content: "";
    height: 10px;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    background: $redblue;
  }
}

#registerModal .modal-content {
  padding: 50px;
}



// page specific styles

.login, .sign-up, .forgot-password, .reset-password {
  background: $redblue no-repeat;
  .container {
    min-height: 90vh;
  }
}

.login-panel, .register-panel {
  background: $white;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
  margin-top: 100px;
  padding: 45px;
  .login-logo {
    max-width: 180px;
    display: block;
    margin: 0 auto 50px;
  }
}

.register-panel {
  margin-top: 50px;
  h2, p {
    margin: auto;
    max-width: 65%;
    text-align: center;
  }
}

.sign-up {
  .login-panel, .register-panel {
    margin-top: 50px;
    padding: 33px;
    h2, p {
      margin: auto;
      max-width: none;
      text-align: left;
    }
    p {
      margin-bottom: 17px;
    }
  }
}

.login-links {
  padding-top: 10px;
  padding-bottom: 30px;
  a {
    color: $white;
  }
}

.admin-films, .admin-pages, .admin-playlists, .admin-users, .admin-reports, .admin-settings {
  .panel {
    margin-top: 50px;
    margin-bottom: 80px;
    td:nth-child(2) {
      text-align: right;
    }
  }
}

.admin-users .panel td:nth-child(2) {
  text-align: left;
}

.admin-settings {
  .form-inline {
    margin-bottom: 30px;
    .wide {
      width: 300px;
    }
  }
}

.admin-page {
  .panel-heading {
    background-color: $blue;
  }
  .add-component {
    width: 100%;
  }
}

.admin-playlist {
  .panel-heading {
    background-color: $blue;
  }
  .add-action {
    width: 100%;
  }
}

.purchase-credits, .donate {
  .container {
    min-height: 80vh;
  }
}

.films {
  &>.container-fluid {
    min-height: 75vh;
  }
}


.tag-delete, .collection-delete {
  cursor: pointer;
}

.tt-menu {
  .tt-suggestion {
    padding: 5px 10px;
    cursor: pointer;
    &:hover {
      background: $blue;
      color: $white;
    }
  }
}

.admin-thumb {
  max-width: 80px;
}

.sortable-placeholder {
  border: 6px dashed #888888;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.components .panel-heading {
  cursor: move;
}


.discloser {
  color: $black;
  display: inline-block;
  text-decoration: none;
  transform: rotate(0);
  transition: all 70ms ease;
  &:hover {
    color: $darkgray;
    text-decoration: none;
  }
  &[aria-expanded="true"] {
    transform: rotate(90deg);
  }
}

.disclosed {
  padding-left: 30px;
}

@media (min-width: $screen-lg-min) {
  .headerbar {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.fancy-dropdown {
  .dropdown-toggle {
    border: 1px solid $gray;
    color: $darkgray;
    display: inline-block;
    line-height: 30px;
    padding: 10px;
    text-decoration: none;
    transition: 100ms all;
    width: 100%;
    @media (max-width: $screen-md-min) {
      width: 75%;
    }
    i {
      font-size: 12px;
      float: right;
      margin-top: 5px;
    }
  }
}

.tools {
  @media (min-width: $screen-lg-min) {
    flex-wrap: wrap;
    justify-content: flex-end;
    display: flex;
  }
  width: 100%;
  .tool {
    margin-left: 30px;
    @media (max-width: $screen-md-min) {
      margin: 10px 0;
    }
    .tool-label {
      background-color: $gray;
      border: 1px solid $gray;
      color: $flatblack;
      display: inline-block;
      line-height: 30px;
      padding: 0 10px;
      transition: 100ms all;
      @media (max-width: $screen-md-min) {
        width: 25%;
      }
    }
    .dropdown-toggle {
      border: 1px solid $gray;
      color: $darkgray;
      display: inline-block;
      line-height: 30px;
      padding: 0 10px;
      text-decoration: none;
      transition: 100ms all;
      width: 200px;
      @media (max-width: $screen-md-min) {
        width: 75%;
      }
      i {
        font-size: 12px;
        float: right;
        margin-top: 5px;
      }
    }
    &.tool-search {
      .form-ish {
        position: relative;
        input {
          border: 1px solid $gray;
          height: 32px;
          outline: none;
          padding-left: 10px;
          padding-right: 25px;
          transition: 100ms;
          vertical-align: top;
          @media (max-width: $screen-md-min) {
            width: 75%;
          }

          &:focus {
            background-color: $fiveblue;
            border: 1px solid $blue;
            color: $black;
          }
        }
        &:hover {
          input {
            border: 1px solid $blue;
          }
        }
      }
      i {
        color: $darkgray;
        font-size: 16px;
        position: absolute;
        right: 10px;
        top: 5px;
      }
    }
    .dropdown-menu {
      margin-top: 0px;
      width: 100%;
    }
    &:hover {
      .tool-label {
        background-color: $blue;
        border: 1px solid $blue;
        color: $white;
      }
      .dropdown-toggle {
        border: 1px solid $blue;
        color: $darkgray;
      }
    }
    .open {
      .tool-label {
        background-color: $blue;
        border: 1px solid $blue;
        color: $white;
      }
      .dropdown-toggle {
        background-color: $fiveblue;
        border: 1px solid $blue;
        color: $black;
      }
      .fa-search {
        color: $black;
        cursor: pointer;
        &:hover {
          color: $blue;
        }
      }
    }
    &.applied {
      .tool-label {
        background-color: $blue;
        border: 1px solid $blue;
        color: $white;
      }
      .dropdown-toggle {
        color: $black;
      }
      .fa-search {
        color: $black;
        cursor: pointer;
        &:hover {
          color: $blue;
        }
      }
    }
  }
}

.single-film {
  .tag {
    background: $darkgray;
    border-radius: 2px;
    color: $white;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
    padding: 0 15px;
    text-transform: capitalize;
    transition: 100ms all;
    &:hover {
      background-color: $flatblack;
      text-decoration: none;
    }
  }
  .buy-dvd {
    border: 1px solid $gray;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 12px;
    text-align: center;
    p {
      margin: 0;
    }
  }
}

.film-card {
  margin-bottom: 34px;
  overflow: hidden;
  position: relative;
  .film-content {
    display: flex;
    position: relative;
    width: 200%;
    .regular-content {
      max-height: 231px;
      padding: 5px 15px;
      position: relative;
      transition: 200ms all;
      width: 50%;
      @media (min-width: $screen-sm-min) {
        &::after {
          content: "";
          height: 10px;
          position: absolute;
          bottom: 0px;
          left: 0px;
          right: -1px;
          background: $redblue;
        }
      }
      @media (max-width: $screen-sm-min) {
        padding-bottom: 50px;
      }
      .redblue-link {
        position: absolute;
        left: 0;
        right: 0;
      }
    }
    .hover-content {
      @media (max-width: $screen-sm-min) {
        display: none;
      }
      align-items: center;
      background: $white;
      display: flex;
      flex-wrap: wrap;
      margin: 0 1px;
      position: relative;
      transform: translateX(-100%);
      transition: 200ms all;
      opacity: 0;
      width: 50%;
      .left-action {
        border-right: 1px solid $gray;
        flex-grow: 1;
        padding: 15px;
        text-align: center;
      }
      .right-action {
        flex-grow: 1;
        padding: 15px;
        text-align: center;
      }
      h5 {
        margin-top: 0;
      }
      .big-blue {
        font-size: 28px;
        margin-bottom: -7px;
      }
      .big-green {
        font-size: 18px;
        line-height: 53px;
        text-transform: uppercase;
      }
      i {
        color: $flatblack;
        font-size: 30px;
      }
    }
    .redblue-link {
      background: $redblue;
      text-align: center;
      width: 100%;
      a {
        color: $white;
        font-size: 14px;
        line-height: 50px;
        text-transform: uppercase;
      }
    }
  }
  @media (min-width: $screen-sm-min) {
    &:hover {
      box-shadow: 0 20px 10px 0 rgba(0,0,0,.20);
      .regular-content {
        opacity: 0;
      }
      .hover-content {
        opacity: 1;
      }
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-left: 1px solid $gray;
    border-right: 1px solid $gray;
    z-index: -1;
  }
  .award::after {
    background: $awardwinner;
    color: $black;
    content: "Award Winner";
    display: block;
    font-size: 12px;
    font-weight: 600;
    left:-65px;
    padding: 5px 65px;
    position: absolute;
    text-transform: uppercase;
    top:22px;
    transform: rotate(-30deg);
  }
  .vcat::after {
    background: $newrelease;
    color: $black;
    content: "VCAT";
    display: block;
    font-size: 12px;
    font-weight: 600;
    left:-65px;
    padding: 5px 105px;
    position: absolute;
    text-transform: uppercase;
    top:22px;
    transform: rotate(-30deg);
  }
}

.single-film-card {
  border-bottom: 1px solid $gray;
  margin-top: 40px;
  position: relative;
  .film-content {
    display: flex;
    position: relative;
    .purchase-options {
      align-items: center;
      display: flex;
      margin: 0px;
      position: relative;
      width: 100%;
      .left-action {
        border-right: 1px solid $gray;
        flex-grow: 1;
        padding: 15px;
        text-align: center;
      }
      .right-action {
        flex-grow: 1;
        padding: 15px;
        text-align: center;
      }
      h5 {
        margin-top: 0;
      }
      .big-blue {
        font-size: 28px;
        margin-bottom: -7px;
      }
      .big-green {
        font-size: 18px;
        line-height: 53px;
        text-transform: uppercase;
      }
      i {
        color: $flatblack;
        font-size: 30px;
      }
      .redblue-link {
        background: $redblue;
        text-align: center;
        width: 100%;
        a {
          color: $white;
          font-size: 14px;
          line-height: 50px;
          text-transform: uppercase;
        }
      }
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-left: 1px solid $gray;
    border-right: 1px solid $gray;
    z-index: -1;
  }
  &::before {
    content: "";
    height: 10px;
    position: absolute;
    top: -10px;
    left: 0px;
    right: -1px;
    background: $redblue;
  }

}


// video player controls

.video-container {
  position: relative;
  video {
    display: block;
    margin: auto;
  }
}

.control{
	color:$gray;
	position:absolute;
	bottom:10px;
	left:5px;
  right:5px;
	z-index:5;
  display: flex;
  margin: auto;
}
/* control bottom part */
.control {
  .btnPlay, .btnFS, .btnLike {
  	background: rgba(0,0,0,0.5);
  	cursor:pointer;
    height: 30px;
    text-align: center;
    width: 40px;
    i.fa {
      font-size: 18px;
      line-height: 30px;
    }
    a {
      color: $gray;
    }
  }
}
.control .selected{
	color:#ccc;
}


/* PROGRESS BAR CSS */
/* Progress bar */
.progress-bar {
	height: 30px;
  width: 100%;
	padding: 0px;
	background: rgba(0,0,0,0.6);
}
.progress {
	width:100%;
	height:30px;
	position:relative;
	cursor:pointer;
	background: rgba(0,0,0,0.4); /* fallback */
	box-shadow: 0 1px 0 rgba(255,255,255,0.1), inset 0 1px 1px rgba(0,0,0,1);
	border-radius:1px;
  margin-bottom: 0;
}
.progress span {
	height:100%;
	position:absolute;
	top:0;
	left:0;
	display:block;
	border-radius:1px;
}
.timeBar{
	z-index:10;
	width:0;
	background: $redblue;
	box-shadow: 0 0 7px rgba(107,204,226,.5);
}

.timeWords {
  bottom: 0px;
  pointer-events: none;
  font-size: 11px;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 15;
  span {
    line-height: 30px;
  }
}

.bufferBar{
	z-index:5;
	width:0;
	background: rgba(255,255,255,0.2);
}

.panel-heading.wysiwyg-panel {
  padding: 7px 0px 7px 10px;
  h5 {
    margin: 0;
    color: $white;
    text-transform: uppercase;
  }
}

.panel-body.wysiwyg-panel {
  padding: 0;
  .form-group {
    .input-group {
      .add-box {
        width: 100%;
      }
    }
  }
}

.pdfs {
  .panel {
    border: none;
    box-shadow: none;
    .panel-body {
      padding: 0;
      table {
        margin: 0;
      }
    }
  }
}

.tags {
  .panel {
    .panel-body {
      padding: 5px;
      .form-group {
        display: flex;
        > * {
          flex-grow: 1;
          flex-shrink: 1;
        }
      }
      .button {
        height: 48px;
        margin-left: 2px;
      }
    }
  }
}

.playlist-selector {
  .panel {
      border: none;
  }
}


.added-tag, .uploaded-video, .uploaded-pdf {
  padding-right: 10px;
}


.input-group-addon {
  background-color: $gray;
  border: none;
  border-radius: 0;
  color: $flatblack;
}

textarea.form-control {
  height: 200px;
}

video::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
}

.events-calendar.columns.single-column {
  background-image: url(../img/datesofevents-background.jpg);
  background-size: cover;
  background-position: center;

  & * {
    color: $white;
  }

}

.events-table.table {
  margin-top: 50px;
  width: 100%;

  & * {
    border-top: none !important;

    text-transform: uppercase;

    & .events-right-align {
      text-align: right;
    }

    & .events-center-align {
      text-align: center;
    }
  }

  @media screen and (max-width: $screen-sm-min) {
    & * .events-center-align {
      text-align: right;
    }

    & * .events-right-align {
      display: none;
    }
  }
}


.single-column-double-column {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $screen-sm-min) {
    flex-direction: column;
  }

  .single-col-side-by-side {
    width: 45%;
    margin-top: 25px;
    padding-left: 1.25%;
    padding-right: 1.25%;

    h3 {
      margin-top: 25px;
    }

    div {

      &.eric {
        background: url(https://s3.amazonaws.com/odbfilms.com/videos/EricGroth_June2018.jpg);
        background-size: cover;
      }

      &.doug {
        background: url(https://s3.amazonaws.com/odbfilms.com/videos/DougTooke_June2018.jpg);
        background-size: cover;
      }

      &.becky {
        background: url(https://s3.amazonaws.com/odbfilms.com/videos/beckygroth.jpg);
        background-size: cover;
      }

      &.tj {
        background: url(https://s3.amazonaws.com/odbfilms.com/videos/tjberden.jpg);
        background-size: cover;
      }

      height: 0;
      padding-top: 60%;
      width: 60%;
      position: relative;
      overflow: hidden;
      margin: auto;
      img {
        position: absolute;
        top:0;
        width: 100%;
        height: 100%;
      }
    }

    @media screen and (max-width: $screen-sm-min) {
      width: 100%;
    }
  }

}
